import React, { useEffect, useState } from "react";
import UseWindowSize from "../UseWindowSize";
import { dynamicUrl } from "../../utils/dynamic-url";
import Link from "next/link";
import "lazysizes";
import { GeneralSkeleton } from "../../pages/dashboard/index";
import { getMagnoliaBanner } from "src/utils/gateway";
import { getImageUrl } from "src/utils/magnolia";

type ProductShowcaseProps = {
  codename: string;
  btnText: string;
  dataCy?: string;
  mobileView?: boolean;
};

const ProductShowcase = ({
  codename,
  btnText,
  dataCy,
  mobileView = false
}: ProductShowcaseProps) => {
  const { width } = UseWindowSize();
  const [isLoading, setIsLoading] = useState(true);
  const [winSize, setWinSize] = useState(null);
  const [data, setData] = useState(null);
  const [href, setHref] = useState(null);

  const getBanner = async () => {
    const response = await getMagnoliaBanner(codename);
    if (!response.error && response.length > 0) {
      const { slugDesktop } = response[0];
      setData(response[0]);
      setHref(dynamicUrl(slugDesktop ?? ''));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBanner();
    setWinSize(width);
  }, []);

  return (
    <div className="product-showcase-wrapper" data-cy={dataCy}>
      {isLoading ? (
        <GeneralSkeleton width="441px" height="177px" borderRadius="15px" />
      ) : (
        <div
          className={`product-showcase-container ${
            mobileView ? "product-showcase-container--mobile" : ""
          }`}
        >
          <Link href={href} as={data.slugDesktop} legacyBehavior>
            <a>
              <img
                className={`${
                  winSize && winSize > 600
                    ? "product-showcase-img-desktop"
                    : "product-showcase-img-mobile"
                } lazyload`}
                data-src={getImageUrl(data.bannerDesktop)}
                alt={data.slugDesktop}
              />
            </a>
          </Link>
          {!mobileView && (
            <Link href={href} as={data.slugMobile} legacyBehavior>
              <a>
                <span className="ajukan-showcase-button">{btnText}</span>
              </a>
            </Link>
          )}
        </div>
      )}
      <style jsx>{`
        .product-showcase-wrapper {
          display: flex;
          justify-content: center;
          background-color: var(--color-white);
        }
        .product-showcase-container {
          max-width: 940px;
          width: 100%;
          height: 360px;
          border-radius: 16px;
          box-shadow: 0 3px 10px 0 #4b4a4a;
          margin: 34px 16px 34px 0;
          position: relative;
        }
        .product-showcase-container img {
          width: 100%;
          height: 100%;
          border-radius: 16px;
          position: relative;
        }
        .ajukan-showcase-button {
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: center;
          border-radius: 6px;
          color: var(--color-red);
          background-color: var(--color-white);
          height: 32px;
          width: 125px;
          margin: 32px;
          font-weight: 600;
          font-size: 12px;
          position: absolute;
          z-index: 10;
          bottom: 0;
          right: 0;
        }
        .ajukan-showcase-button:hover {
          cursor: pointer;
        }
        .product-showcase-img-desktop {
          width: 100%;
          cursor: pointer;
        }
        .product-showcase-img-mobile {
          display: none;
          width: 100%;
        }
        .product-showcase-container--mobile {
          height: auto;
          width: 90%;
        }
        @media only screen and (max-width: 600px) {
          .product-showcase-container {
            margin: 34px 10px 34px 0;
          }
          .product-showcase-container,
          .product-showcase-container--mobile {
            height: auto;
            width: 90%;
          }
          .ajukan-showcase-button {
            margin: 12px;
            font-size: 7px;
            width: 85px;
            height: 20px;
          }
          .product-showcase-img-desktop {
            display: none;
          }
          .product-showcase-img-mobile {
            display: block;
          }
          .product-showcase-description li {
            font-size: 12px;
            font-weight: bold;
          }
          .product-showcase-top-component {
            min-height: 370px;
          }
        }
      `}</style>
    </div>
  );
};

export default ProductShowcase;
