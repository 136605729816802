import Layout from "../../components/Layout";
import { defBreadcrumb } from "../../data/DataBreadcrumb";
import NextHead from "../../components/Seo/NextHead";
import Link from "next/link";
import { SliderRelatedPromo } from "../../components/UserInterface/SliderRelatedPromo";
import { useEffect, useState } from "react";
import UseWindowSize from "../../components/UseWindowSize";
import fetch from "../../utils/fetch";
import BlogRelatedItems from "../../components/Blog/BlogRelatedItems";
import ProductShowcase from "../../components/IndexPage/ProductShowcase";
import { Slider } from "../../components/Slider";
import Button from "../../components/UserInterface/Button";
import HandlingContract from "../../components/Contract/HandlingContract";
import { getCookies } from "../../utils/helpers";
import IAP from "../../components/Contract/IAP";
import { useRouter } from "next/navigation";
import { encrypt } from "../../utils/cipher";
import { Link as MagnoliaLink } from "src/components/Magnolia";

type DashboardItemComponentProps = {
  title: string;
  url?: string;
  children: any;
};

const DashboardItemComponent = ({
  title,
  url,
  children,
}: DashboardItemComponentProps) => {
  return (
    <div className="dashboard_item_component_wrapper">
      <div className="dashboard_item_component_header">
        <span className="dashboard_item_component_title">{title}</span>
        {url && (
          <MagnoliaLink href={url}>
            <a href="" className="dashboard_item_component_see_all">
              Lihat Semua
            </a>
          </MagnoliaLink>
        )}
      </div>
      <div className="dashboard_item_component_body">{children}</div>
      <style jsx>{`
        .dashboard_item_component_wrapper {
          padding: 30px 0 0 10px;
          width: auto;
        }
        .dashboard_item_component_header {
          padding-left: 10px;
          padding-right: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
        }
        .dashboard_item_component_title {
          font-weight: bold;
          font-size: 16px;
        }
        .dashboard_item_component_see_all {
          color: var(--color-red);
          font-weight: bold;
          font-size: 12px;
        }
        @media only screen and (max-width: 425px) {
          .dashboard_item_component_see_all {
            font-size: 12px;
          }
        }
      `}</style>
    </div>
  );
};

export const GeneralSkeleton = ({
  width = null,
  height = null,
  borderRadius = null,
}) => {
  return (
    <div className="skeleton-wrapper">
      <style jsx>{`
        @keyframes load {
          from {
            left: -150px;
          }
          to {
            left: 100%;
          }
        }
        .skeleton-wrapper {
          width: ${width ? width : "350px"};
          height: ${height ? height : "150px"};
          margin: 0 12px 24px;
          display: flex;
          align-content: center;
          flex-direction: column;
          align-items: flex-start;
          overflow: hidden;
          position: relative;
          background-color: #d8d8d8;
          border-radius: ${borderRadius ? borderRadius : ""};
        }
        .skeleton-wrapper::before {
          content: "";
          display: block;
          position: absolute;
          left: -150px;
          top: 0;
          width: ${width ? width : "350px"};
          height: ${height ? height : "150px"};
          background: -webkit-gradient(
            linear,
            left top,
            right top,
            from(transparent),
            color-stop(rgba(255, 255, 255, 0.3)),
            to(transparent)
          );

          background: linear-gradient(
            90deg,
            transparent,
            rgba(255, 255, 255, 0.3),
            transparent
          );
          animation: load 1s infinite;
        }
      `}</style>
    </div>
  );
};

const CarouselRewards = ({ data }) => {
  return (
    <div className="dashboard_carousel_container">
      <div className="dashboard_carousel_item_slider">
        <Slider data={data} infiniteScroll={true} autoScroll={true} />
      </div>
      <div className="dashboard_carousel_item_button">
        <Link href="/rewards" as="/rewards" legacyBehavior>
          <a href="">
            <Button
              text="Lihat Semua"
              fontSize="12px"
              fontSizeMobile="12px"
              padding="8px 10px"
              bgColor="var(--color-gray-medium)"
              opacity
              onClick={() => {}}
            />
          </a>
        </Link>
      </div>
      <style jsx>{`
        .dashboard_carousel_container {
          position: relative;
        }
        .dashboard_carousel_item_button {
          position: absolute;
          margin: 0 10px;
          bottom: 10%;
          right: 0;
          z-index: 3;
        }
      `}</style>
    </div>
  );
};

const ArraySkeleton = ({ width, height, borderRadius }) => {
  return (
    <>
      {[...Array(4)].map(() => {
        return (
          <GeneralSkeleton
            width={width}
            height={height}
            borderRadius={borderRadius}
          />
        );
      })}
    </>
  );
};

const Dashboard = ({
  pageTitle,
  descriptionSEO,
  breadcrumb,
  asPath,
  contractListData,
  approvedContractList,
  redirectUrl,
}) => {
  const { width } = UseWindowSize();
  const [winSize, setWinSize] = useState(null);
  const [promos, setPromos] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [rewards, setRewards] = useState([]);
  const [isFetchingPromo, setIsFetchingPromo] = useState(false);
  const [isFetchingBlog, setIsFetchingBlog] = useState(false);
  const [isFetchingRewards, setIsFetchingRewards] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setWinSize(width);
  });

  useEffect(() => {
    localStorage.setItem("contract-list", JSON.stringify(contractListData));
    if (approvedContractList?.length > 1) {
      router.push("/dashboard/action");
    } else if (approvedContractList?.length === 1) {
      window.location.replace(redirectUrl);
    }
  }, []);

  const getPromo = async () => {
    if (isFetchingPromo) return;

    setIsFetchingPromo(true);
    const { status, data } = await fetch("GET", `/kentico/promo-list`, {
      commodity: "",
      elements: "promo",
    });

    if (status == 200) {
      setPromos(data);
      setIsFetchingPromo(false);
    }
  };

  const getBlog = async () => {
    if (isFetchingBlog) return;

    setIsFetchingBlog(true);

    const { status, data } = await fetch("GET", `/kentico/blog`, {
      limit: 4,
      slug: "",
    });

    if (status == 200) {
      setBlogs(data.item);
      setIsFetchingBlog(false);
    }
  };

  const getRewards = async () => {
    if (isFetchingRewards) return;

    setIsFetchingRewards(true);

    const { status, data } = await fetch("GET", `/kentico/slider`, {
      codename: "rewards_slider",
    });

    if (status == 200) {
      setRewards(data);
      setIsFetchingRewards(false);
    }
  };

  useEffect(() => {
    //get based on order
    getPromo();
    getBlog();
    getRewards();
  }, []);

  return (
    <Layout height="auto">
      <NextHead
        title={pageTitle}
        description={descriptionSEO}
        url={asPath}
        breadcrumb={breadcrumb}
      ></NextHead>
      <div className="dashboard_wrapper">
        {isFetchingRewards ? (
          <GeneralSkeleton width="auto" height="180px" borderRadius="10px" />
        ) : (
          <CarouselRewards data={rewards} />
        )}
        <IAP
          ctaLink="/dashboard/action"
          infoCopy="Selesaikan Kewajiban Tertunda"
          alwaysMobile={true}
          rawDataContract={contractListData}
        />
        <HandlingContract rawDataContract={contractListData} />
        <DashboardItemComponent title="Produk Pilihan">
          <ProductShowcase
            codename="product_showcase_home"
            btnText="Lihat Selengkapnya"
            mobileView={true}
          ></ProductShowcase>
        </DashboardItemComponent>
        <div className="dashboard_separator" />
        <DashboardItemComponent
          title="Promo Untukmu"
          url="/promo-dan-penawaran"
        >
          {isFetchingPromo ? (
            <SliderRelatedPromo>
              <ArraySkeleton width="300px" height="160px" borderRadius="10px" />
            </SliderRelatedPromo>
          ) : (
            <SliderRelatedPromo>
              {promos.map((data, key) => {
                return (
                  <div key={key}>
                    <MagnoliaLink href={`/promo-dan-penawaran/${data.slug}`}>
                      <a>
                        <img
                          className="promo-slider-img"
                          src={data.banner + "?w=" + winSize * 0.9}
                          alt={data.slug}
                        />
                      </a>
                    </MagnoliaLink>
                  </div>
                );
              })}
            </SliderRelatedPromo>
          )}
        </DashboardItemComponent>
        <div className="dashboard_separator" />
        {/* Blog section */}
        <DashboardItemComponent title="Blog" url="/blog">
          {isFetchingBlog ? (
            <div>
              <ArraySkeleton width="92%" height="75px" borderRadius="10px" />
            </div>
          ) : (
            <div style={{ paddingLeft: "10px", paddingRight: " 20px" }}>
              <BlogRelatedItems blogData={blogs} isAlwaysMobile={true} />
            </div>
          )}
        </DashboardItemComponent>
      </div>
      <style jsx>{`
        .dashboard_wrapper {
          max-width: 500px;
          width: 100%;
          margin-left: auto;
          margin-right: auto;
        }
        .dashboard_separator {
          background-color: #f4f4f4;
          height: 10px;
        }

        .promo-slider-img {
          max-height: 216px;
          width: calc(500px / 1.5);
          height: 100%;
          margin-right: 10px;
          border-radius: 8px;
        }

        .no-contract-wrapper {
          background-color: var(--color-white-soft);
          margin: 16px 30px 20px 30px;
          border-radius: 5px;
          font-size: 12px;
        }

        .no-contract-container {
          display: flex;
          justify-content: space-around;
          align-items: center;
          width: 100%;
        }

        .no-contract-container p {
          width: 330px;
        }
        .no-contract-container img {
          margin: 16px;
        }
        @media only screen and (max-width: 425px) {
          .promo-slider-img {
            width: calc(100vw / 1.3);
          }
        }
      `}</style>
    </Layout>
  );
};

Dashboard.getInitialProps = async ({ asPath, req, res }) => {
  const pageTitle = "Dashboard";
  const descriptionSEO = "Dashboard";

  const token = getCookies("token", req);
  const rtoken = getCookies("rtoken", req);

  let contractListData = {} as any;
  let approvedContractList = {} as any;
  let redirectUrl = "";

  if (rtoken) {
    const invoiceUploadResponse = await fetch(
      "POST",
      "/application-service/get-user-detail",
      { authToken: token },
      true
    );
    const invoiceUploadData = invoiceUploadResponse?.data;
    const contractListResponse = await fetch(
      "POST",
      "/contract/get-all-contracts",
      { data: encrypt({ authToken: token, cuid: invoiceUploadData.cuid }) },
      true
    );

    contractListData = contractListResponse?.data;

    approvedContractList = contractListData?.contracts?.filter(
      (data) => data.status === "APPROVED"
    );
    if (res) {
      res.setHeader("Cache-Control", "no-store");
      if (
        contractListResponse?.status !== 200 ||
        contractListResponse?.data?.httpStatus !== 200
      ) {
        res?.writeHead(302, { location: "/authlogin?rtoken=" + rtoken });
        res?.end();
      }
      if (approvedContractList?.length === 1) {
        redirectUrl =
          approvedContractList[0].requestId &&
          !approvedContractList[0].isMistake
            ? `${process.env.clientZoneAuth}${approvedContractList[0].requestId}`
            : `${process.env.credstoreAuth}${approvedContractList[0].contractNumber}`;
        res?.writeHead(302, {
          location: redirectUrl,
        });
        res?.end();
      }
      if (rtoken) {
        res?.writeHead(302, { location: "/authlogin?rtoken=" + rtoken });
        res?.end();
      }
    }
  }

  if (token === false && rtoken === false) {
    res.writeHead(302, {
      location: process.env.credstore + process.env.clientId,
    });
    res.end();
  }

  const breadcrumb = [
    ...defBreadcrumb,
    {
      "@type": "ListItem",
      position: "2",
      item: {
        "@id": process.env.baseUrl + asPath,
        name: pageTitle,
      },
    },
  ];

  return {
    pageTitle,
    descriptionSEO,
    breadcrumb,
    asPath,
    contractListData,
    approvedContractList,
    redirectUrl,
  };
};

export default Dashboard;
